import classNames from "classnames";
import PropTypes from "prop-types";
import { Badge, Col, Row } from "react-bootstrap";

import { Download, Refresh } from "../../../../components/Icon";
import { STATUS, STATUS_TITLE } from "../../../../constants/exporterParcels";

import styles from "./StatusCell.module.scss";

const STATUS_COMPONENT = {
  PENDING: (
    <Badge pill variant="warning">
      {STATUS_TITLE.PENDING}
    </Badge>
  ),
  PROCESSING: (
    <Badge pill variant="success">
      {STATUS_TITLE.PROCESSING}
    </Badge>
  ),
  COMPLETE: (
    <Badge pill variant="success" className={"badge-outlined"}>
      {STATUS_TITLE.COMPLETE}
    </Badge>
  ),
};

const StatusCell = ({ status, onClickDownload, onClickRefresh }) => (
  <Row className={classNames(styles.statusCell, "row")}>
    <Col md={6}>{STATUS_COMPONENT[status]}</Col>
    <Col md={6}>
      {status === STATUS.COMPLETE && (
        <button
          onClick={onClickDownload}
          className={classNames(styles.iconButtonDownload, "ml-2")}
        >
          <Download classNameSvg={"pb-0"} />
        </button>
      )}
      {status !== STATUS.COMPLETE && (
        <button onClick={onClickRefresh} className={classNames("ml-2")}>
          <Refresh classNameSvg={"pb-0"} />
        </button>
      )}
    </Col>
  </Row>
);

StatusCell.defaultProps = {};

StatusCell.propTypes = {
  exportAddressBook: PropTypes.object,
  onClickDownload: PropTypes.func,
  onClickRefresh: PropTypes.func,
};

export default StatusCell;
