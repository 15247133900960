import { get, zipWith } from "lodash";
import moment from "moment-timezone";

import { PARCEL_STATUS } from "@dpdgroupuk/redback-enums";

import { APIS_ORIGIN_URL } from "../../apis";
import {
  AddWatchList,
  AlternateAddress,
  CallMe,
  ChangeAddress,
  ChangeAddressUpgrade,
  ChangeDate,
  CivilUnrest,
  CollectFromPickup,
  ContactDetails,
  Covid,
  Depot,
  Destroy,
  Document,
  Fire,
  FlightDelay,
  Flood,
  LocalEvent,
  Neighbour,
  NoSailing,
  Olympic,
  Precise,
  RemoveWatchList,
  ReturnToSender,
  SafePlace,
  Snowflake,
  Traffic,
  Unexpected,
  UpgradeDelivery,
  Warning,
  Winds,
} from "../../components/Icon";
import { DEFAULT_DATE_TIME_FORMAT } from "../../constants/dateFormats";
import {
  calculatePositionInPeriod,
  chooseSmallerDate,
  formatInternalTime,
  formatTime,
  getCurrentDateTime,
} from "../../utils/date";
import {
  CollectFromDepotParcelView,
  EstimatedDateParcelView,
  GeneralParcelView,
  IssueParcelView,
  OutForDeliveryParcelView,
  PickupDeliveryParcelView,
} from "./view";

export const issueIcons = [
  Unexpected,
  Fire,
  Flood,
  Snowflake,
  CivilUnrest,
  NoSailing,
  Traffic,
  Winds,
  FlightDelay,
  Olympic,
  LocalEvent,
  Covid,
];

export const statusIcons = {
  [PARCEL_STATUS.RFI_REASON]: Warning,
};

const icons = {
  WTC: AddWatchList,
  WTR: RemoveWatchList,
  SFP: SafePlace,
  DTN: Neighbour,
  LOC: Document,
  PKU: CollectFromPickup,
  ALT: ChangeAddress,
  ALI: ChangeAddress,
  ALA: AlternateAddress,
  ALU: ChangeAddressUpgrade,
  RED: ChangeDate,
  RTC: ReturnToSender,
  RTI: ReturnToSender,
  TBC: Depot,
  CME: CallMe,
  KMI: ContactDetails,
  UPC: UpgradeDelivery,
  UPG: UpgradeDelivery,
  REI: ChangeDate,
  DIP: Destroy,
  ONE: Precise,
};

const DEFAULT_SIZE = [940, 432];
const DEFAULT_ZOOM = 16;

const getDate = event =>
  moment(`${event.date} ${event.time}`, DEFAULT_DATE_TIME_FORMAT);

export const getMapUrl = (pacelCode, [width, height] = DEFAULT_SIZE) =>
  `${APIS_ORIGIN_URL}/map/route?size=${width}*${height}&parcelCode=${pacelCode}&themeEvent=collection`;

export const getDriverImageUrl = (imageId, imageType) =>
  `${APIS_ORIGIN_URL}/images/${imageId}?&imageType=${imageType}`;

export const getEventLocationMapUrl = (
  { latitude, longitude },
  [width, height] = DEFAULT_SIZE
) =>
  `${APIS_ORIGIN_URL}/map/coordinates?latLong=${[
    latitude,
    longitude,
  ].toString()}&size=${width}*${height}&zoom=${DEFAULT_ZOOM}`;

export const getImageUrl = (imageKey, imageType) =>
  `${APIS_ORIGIN_URL}/images/${imageKey}?imageType=${imageType}`;

const zipActions = disabled => (a, b) =>
  zipWith(a, b, (action, title) => ({
    icon: icons[action],
    action,
    title,
    disabled,
  }));

export const transformActions = ({
  availableActions = [],
  availableActionsName = [],
}) => [...zipActions(false)(availableActions, availableActionsName)];

export const sortByDate = (data = []) =>
  data.sort((a, b) => getDate(b) - getDate(a));

const getProgressStatus = (parcel, route, driver) => {
  const driverName =
    get(route, "driverDisplayName") || get(driver, "driverName");
  const totalCompletedStops =
    route.completedCollectionStops + route.completedDeliveryStops;

  const myStop = parcel.deliveryDepot.route.stop.stopNumber || 0;

  const currentStop =
    totalCompletedStops >= myStop && myStop > 0
      ? myStop - 1
      : totalCompletedStops;

  if (currentStop < 1) {
    return null;
  }

  const driverNamePart = driverName ? `, ${driverName},` : "";

  return `Your driver${driverNamePart} is currently making stop number ${currentStop}. You are stop number ${myStop}.`;
};

export const getParcelProgressData = (parcel, route, driver) => {
  const deliveryWindowFrom = get(
    parcel,
    "deliveryDepot.route.stop.deliveryWindowFrom",
    ""
  );
  const deliveryWindowTo = get(
    parcel,
    "deliveryDepot.route.stop.deliveryWindowTo",
    ""
  );
  const driverName =
    get(route, "driverDisplayName") || get(driver, "driverName");

  const progressStatus = getProgressStatus(parcel, route, driver);

  const routeTimeInSeconds = route.routeTime;
  const routeDateTime = routeTimeInSeconds
    ? formatInternalTime(routeTimeInSeconds)
    : null;

  const driverStartTime = chooseSmallerDate(routeDateTime, deliveryWindowFrom);

  let currentPosition = calculatePositionInPeriod(
    getCurrentDateTime(),
    driverStartTime,
    deliveryWindowTo
  );
  // add min value as 5 to avoid negative values in progress
  currentPosition = Math.max(currentPosition, 5);

  const startPosition = calculatePositionInPeriod(
    deliveryWindowFrom,
    driverStartTime,
    deliveryWindowTo
  );

  const progressData = {
    showProgressBar: currentPosition < 100,
    progressStatus,
    currentPosition,
    startPosition,
    windowStartTime: formatTime(deliveryWindowFrom),
    windowEndTime: formatTime(deliveryWindowTo),
    vehicleTypeCode: get(driver, "vehicleTypeCode"),
  };

  return {
    code: parcel.parcelCode,
    customerImageLogo: parcel.customerImageLogo,
    deliveryNumber: `Parcel No. ${parcel.parcelNumber}`,
    statusHtml: parcel.parcelStatusHtml,
    query: "parcelCode",
    electricMessage: `${driverName} proudly\ndrives a 100%\nelectric ${driver.vehicleTypeName}`,
    isVehicleElectric: !!get(parcel, "deliveryDetails.onRouteElectric"),
    showIdMessage: !!get(parcel, "parcelStatusInfo"),
    parcelStatusInfoMessage: get(parcel, "parcelStatusInfo"),
    progressData,
  };
};

export const getParcelComponent = parcelStatusType => {
  switch (parcelStatusType) {
    case PARCEL_STATUS.ISSUE:
    case PARCEL_STATUS.RFI_REASON:
      return IssueParcelView;
    case PARCEL_STATUS.COLLECT_FROM_DEPOT_INSTRUCTIONS:
      return CollectFromDepotParcelView;
    case PARCEL_STATUS.OUT_FOR_DELIVERY:
      return OutForDeliveryParcelView;
    case PARCEL_STATUS.PICKUP_DELIVERY:
      return PickupDeliveryParcelView;
    case PARCEL_STATUS.ESTIMATED_DATE:
      return EstimatedDateParcelView;
    default:
      return GeneralParcelView;
  }
};
